
import { defineComponent, ref, onMounted } from "vue";
import { Field, Form, useResetForm, FormActions } from "vee-validate";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TrackerModal from "./TrackerModal.vue";
import {
  getCustomersList,
  getTrackersListing,
  enable_depth_Map,
  enable_mobility,
  enableNotification,
  wasteType_list,
  update_bin_dimension,
  update_bin_waste_type,
  update_bin_location,
  update_bin_offset,
  update_bin_full_at,
  deleteTrackerFromPlatform,
  binsConfiguration,
} from "@/store/api/devices";
import moment, { localeData } from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { config } from "vuex-module-decorators";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    TrackerModal,
  },
  setup() {
    const data = ref<any[]>([]);
    const waste_type_list = ref<any>();
    const trackerdata = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const trackerDataforUpdate = ref<any[]>([]);
    const bin_ids = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const payload = ref({
      tracker_id: "",
      apply_offset_raw_distance: "",
      offset_raw_distance: "",
    });
    const config = ref({
      tracker_id: {},
      routing_weight_limit: 2000,
      routing_cost_for_emptying: 5,
      routing_service_time: 120,
      routing_volume: 1000,
      routing_enabled: 1,
      pickup_threshold: 0.75,
    });
    const search = ref<string>("");
    const cardData = ref<string>("");
    const permissions = ref<any>("");
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Variant Id",
        key: "variant_id",
      },
      {
        name: "Company",
        key: "se_account_id",
      },
      {
        name: "Radius",
        key: "default_radius",
      },
      {
        name: "Battery",
        key: "battery",
      },
      {
        name: "Action",
        key: "diagnostic_uplink",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      customers.value = await getCustomersList();
      console.log("loading data", loadData());
      setCurrentPageBreadcrumbs("Trackers", [""]);
    });
    const loadData = async () => {
      loading.value = true;
      let value = await wasteType_list();
      waste_type_list.value = value.data;
      let binValue = await getTrackersListing(pagination.value);
      trackerdata.value = binValue.devices;
      total.value = binValue.total_number_of_records;
      loading.value = false;
    };
    const updateData = (data) => {
      console.log("button is clicking", data);
      trackerDataforUpdate.value = data;
    };
    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD HH:MM:SS");
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };
    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    //Ena & Dis depth map
    const Depthmapoption = () => {
      Swal.fire({
        title: "Depth Map",
        input: "select",
        inputOptions: {
          1: "Enable Depth Map",
          0: "Disable Depth Map",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enable_depth_Map({
          tracker_id: Object.values(bin_ids.value),
          is_depth_map: result.value,
        });
        console.log(res);
      });
    };
    // Notifications
    const BinNotifications = () => {
      Swal.fire({
        title: "Bins Notification",
        input: "select",
        inputOptions: {
          1: "Enable Notification",
          0: "Disable Notification",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enableNotification({
          tracker_id: Object.values(bin_ids.value),
          is_notification_enabled: result.value,
        });
        console.log(res);
      });
    };

    //Update mobility options
    const Mobilityoption = () => {
      Swal.fire({
        title: "Mobility",
        input: "select",
        inputOptions: {
          1: "Enable Mobility",
          0: "Disable Mobility",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enable_mobility({
          tracker_id: Object.values(bin_ids.value),
          is_mobility: result.value,
        });

        console.log(res);
      });
    };
    //Update offset
    const offs = async (data) => {
      const resp = await update_bin_offset({
        tracker_id: Object.values(bin_ids.value),
        apply_offset_raw_distance: data.apply_offset_raw_distance,
        offset_raw_distance: data.offset_raw_distance,
      });
      loadData();
      document.getElementById("offsetclosemodal")?.click();
      console.log(resp);
    };

    // Bins Configuration for Routing

    const submit = async () => {
      const tracker_id = Object.values(bin_ids.value);
      console.log("bins ids", tracker_id);
      config.value.tracker_id = Object.values(bin_ids.value);
      console.log("Data from Routing Config", config.value);
      const resp = binsConfiguration(config.value)
        .then((resp) => {
          Swal.fire({
            icon: "success",
            title: `${resp.message}`,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        });
      modalClose();
      loadData();
      document.getElementById("binsmodal")?.click();
    };
    const modalClose = () => {
      bin_ids.value = [];
      config.value = {
        tracker_id: "",
        routing_weight_limit: 2000,
        routing_cost_for_emptying: 5,
        routing_service_time: 120,
        routing_volume: 1000,
        routing_enabled: 1,
        pickup_threshold: 0.75,
      };
    };

    //Update bins dimension values
    const BinDimensionsin = () => {
      Swal.fire({
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        title: "Bin Dimensions",
        html:
          "<div><p>Legnth:</p>" +
          '<input id="swal-input1" class="swal2-input">' +
          "<p>Width:</p>" +
          '<input id="swal-input2" class="swal2-input">' +
          "<p>Height:</p>" +
          '<input id="swal-input3" class="swal2-input">',
        customClass: "swal-height",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const base_length =
            Swal.getPopup().querySelector("#swal-input1").value;
          const base_width =
            Swal.getPopup().querySelector("#swal-input2").value;
          const bin_height =
            Swal.getPopup().querySelector("#swal-input3").value;
          return {
            base_length: base_length,
            base_width: base_width,
            bin_height: bin_height,
          };
        },
      }).then(async (result) => {
        const res = await update_bin_dimension({
          tracker_id: Object.values(bin_ids.value),
          base_length: result.value.base_length,
          base_width: result.value.base_width,
          bin_height: result.value.bin_height,
        });
        console.log(res);
      });
    };
    //Update bins location values
    const BinLocation = () => {
      Swal.fire({
        title: "Bin Locations",
        html:
          "<div><p>Address:</p>" +
          '<input id="swal-input11" class="swal2-input">' +
          "<p>lat:</p>" +
          '<input id="swal-input22" class="swal2-input">' +
          "<p>lng:</p>" +
          '<input id="swal-input33" class="swal2-input">',
        customClass: "swal-height",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const address = Swal.getPopup().querySelector("#swal-input11").value;
          const lat = Swal.getPopup().querySelector("#swal-input22").value;
          const lng = Swal.getPopup().querySelector("#swal-input33").value;
          return {
            address: address,
            lat: lat,
            lng: lng,
          };
        },
      }).then(async (result) => {
        console.log(result.value);

        if (result.isConfirmed) {
          const resp = await update_bin_location({
            tracker_id: Object.values(bin_ids.value),
            address: result.value.address,
            lat: result.value.lat,
            lng: result.value.lng,
          });
          console.log(resp);
        }
      });
    };
    //Update bins full_at values
    const BinFull_At = () => {
      Swal.fire({
        title: "Bin Full At",
        html:
          "<div><p>Full At:</p>" +
          '<input id="swal-input11" class="swal2-input">',
        customClass: "swal-height",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const full_at = Swal.getPopup().querySelector("#swal-input11").value;

          return {
            full_at: full_at,
          };
        },
      }).then(async (result) => {
        console.log(result.value);

        if (result.isConfirmed) {
          console.log(result.value);
          const resp = await update_bin_full_at({
            tracker_id: Object.values(bin_ids.value),
            full_at: result.value.full_at,
          });
          loadData();
          console.log(resp);
        }
      });
    };
    const selectAll = (val) => {
      if (val === true) {
        trackerdata.value.forEach((x) => {
          bin_ids.value.push(x.tracker_id);
        });
      } else {
        bin_ids.value = bin_ids.value.filter(
          (d) => !trackerdata.value.find((rm) => rm.tracker_id === d)
        );
      }
    };
    const isChecked = (val) =>
      bin_ids.value.find((element) => {
        if (element === val) {
          return true;
        } else false;
      });

    const unCheck = (val) => {
      bin_ids.value.splice(
        bin_ids.value.findIndex((a) => a === val),
        1
      );
    };

    const BinWasteTyep = () => {
      var model: any = [];
      Object.keys(waste_type_list.value).forEach(function (key) {
        model[key] = waste_type_list.value[key].name;
      });

      Swal.fire({
        title: "Waste Type",
        input: "select",
        inputOptions: model,
        showCancelButton: true,
        inputPlaceholder: "Select a option",
      }).then(async (inputValue) => {
        if (inputValue.isConfirmed === true) {
          const res = await update_bin_waste_type({
            tracker_id: Object.values(bin_ids.value),
            waste_type_id: inputValue.value,
          });
          console.log(res);
        }
      });
    };
    const selectBins = (val) => {
      if (!isChecked(val.tracker_id)) {
        bin_ids.value.push(val.tracker_id);
      } else {
        unCheck(val.tracker_id);
      }
    };
    const deleteDevice = async (val) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteTrackerFromPlatform(val);
          console.log(resp);
          Swal.fire({
            text: "Device has been Deleted successfully!",
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loadData();
        }
      });
    };

    return {
      data,
      trackerdata,
      headerConfig,
      customers,
      total,
      loading,
      search,
      pagination,
      cardData,
      currentChange,
      searchItems,
      getCustomerName,
      itemsPerPageChange,
      onCustomerChange,
      dateTime,
      trackerDataforUpdate,
      Depthmapoption,
      BinNotifications,
      Mobilityoption,
      BinDimensionsin,
      BinWasteTyep,
      BinLocation,
      BinFull_At,
      updateData,
      bin_ids,
      selectBins,
      selectAll,
      isChecked,
      payload,
      offs,
      deleteDevice,
      permissions,
      submit,
      modalClose,
      config,
    };
  },
});


import { defineComponent, ref, watch, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  updateTracker,
  getUserRole,
  getCustomersList,
  getTrackersListing,
} from "@/store/api/devices";
import { hideModal } from "@/core/helpers/dom";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "AddUser",
  props: {
    trackerRecord: { type: Object, required: true },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const roles = ref<any[]>([]);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    let tracker = ref<any>([]);
    const customers = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const errors = ref<any[]>([]);
    const trackerdata = ref<any[]>([]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    watch(
      () => props.trackerRecord,
      (v) => {
        tracker.value = v;
      }
    );
    onMounted(async () => {
      getCustomersList().then((response) => {
        customers.value = response;
      });
      getUserRole().then((response) => {
        roles.value = response.data;
      });
    });
    const loadData = async () => {
      loading.value = true;
      let value = await getTrackersListing(pagination.value);
      trackerdata.value = value.devices;
      total.value = value.total_number_of_records;
      loading.value = false;
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });
    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let res = updateTracker(tracker.value.tracker_id, tracker.value)
        .then((resp) => {
          Swal.fire({
            text: "Save Successfylly",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loadData();
          document.getElementById("editnewmodalclose")?.click();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
          loadData();
          document.getElementById("editnewmodalclose")?.click();
        });
    };

    const modalClose = () => {
      loadData();
    };

    return {
      tracker,
      roles,
      submit,
      customers,
      submitButtonRef,
      createAPIKeyModalRef,
      modalRef,
      validationSchema,
      modalClose,
    };
  },
});
